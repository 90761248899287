<template>
  <div class="aiMain">
    <!-- <div class="top">
      <div class="logo">
        <img src="../../assets/home/small-logo.png" alt="" />
        <p class="companyName">平行方舟</p>
      </div>
      <div class="avatar">
        <el-button type="primary" v-if="loginBtn" @click="loginShowClick()">登录</el-button>
        <el-popover
        v-else
            placement="top"
            width="160"
            v-model="visible"
            trigger="hover"
          >
            <p style="padding: 10px 0px">确定要退出登录吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="cancelLogout()"
                >取消</el-button
              >
              <el-button type="primary" size="mini" @click="logout()"
                >确定</el-button
              >
            </div>
            <img
              class="avatarLogin"
              src="../../assets/home/logo.jpg"
              alt=""
              slot="reference"
            />
          </el-popover>
      </div>
    </div> -->
    <!-- <div class="left">
      <img class="threeD" src="../../assets/drive/3d.png" alt="">
    </div> -->
    <div v-show="D3show">
      <D3></D3>
    </div>
    <div v-show="!D3show">
      <div class="class">
        <img @click="backMap()" style="width: 30px;" src="https://oss.zijingshuke.com/1726793164015.png" alt="">
        <div
          v-for="(item, i) in Arr"
          :key="i"
          :class="index == i ? 'classActive' : 'classFont'"
          @click="changeIndex(item.id, item.url)"
        >
          {{ item.name }}
        </div>
      </div>
      <AIRAG v-show="index == 0"></AIRAG>
      <AIData v-show="index == 1"></AIData>
    </div>

    <div class="login" v-show="loginShow">
      <div class="login-box">
        <div class="loginIn">登录</div>
        <img
          class="loginImg"
          @click="loginClose()"
          src="../../assets/drive/close.png"
          alt=""
        />
        <div class="fromBox">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="账号" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入手机号或者邮箱"
              ></el-input>
            </el-form-item>

            <el-form-item label="密码" prop="password">
              <el-input
                v-model="ruleForm.password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <div class="flex">
              <el-checkbox class="checkbox" v-model="checked"
                >记住密码</el-checkbox
              >
              <!-- <p class="forgetpsw">忘记密码？</p> -->
            </div>

            <el-form-item>
              <el-button
                class="elBtn"
                type="primary"
                @click="submitForm('ruleForm')"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="registName" @click="registShowClick()">立即注册</div>
      </div>
    </div>
    <div class="regist login" v-show="registShow">
      <!-- <div class="registBox">
        <div  class="registTitle">注册</div>
        <div class="flex">
          <span class="span">用户名：</span>
          <el-input class="input" placeholder="请输入用户名" v-model="userName"
          prefix-icon="el-icon-user"></el-input>
        </div>
        <div>
          <span>手机号：</span>
          <el-input placeholder="请输入手机号" v-model="phoneNum"
          prefix-icon="el-icon-phone-outline"></el-input>
        </div>
        <div>
          <span>邮箱：</span>
          <el-input placeholder="请输入邮箱名" v-model="Email"
         prefix-icon="el-icon-message"></el-input>
        </div>
        <div>
          <span>密码：</span>
          <el-input placeholder="请输入密码" v-model="input"
          prefix-icon="el-icon-lock"></el-input>
        </div>
        <div>
          <span>确认密码：</span>
          <el-input placeholder="请确认密码" v-model="confirmPsw"
          prefix-icon="el-icon-lock"></el-input>
        </div>
        <div class="btnBox">
          <el-button class="btn">注册</el-button>
        </div>
        
      </div> -->
      <div class="regist-box">
        <div class="title">注册</div>
        <img
          class="registImg"
          @click="registClose()"
          src="../../assets/drive/close.png"
          alt=""
        />
        <el-form
          :model="ruleForms"
          status-icon
          :rules="rule"
          ref="ruleForms"
          label-width="100px"
          class="demo-ruleForms"
        >
          <el-form-item label="账户名" prop="account">
            <el-input
              v-model="ruleForms.account"
              placeholder="请输入账户名"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="ruleForms.phone"
              placeholder="请输入手机号"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model="ruleForms.email"
              placeholder="请输入邮箱地址"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pass">
            <el-input
              v-model="ruleForms.pass"
              placeholder="请输入密码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              v-model="ruleForms.checkPass"
              placeholder="请再次输入密码"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              class="registBtn"
              type="primary"
              @click="submitForms('ruleForms')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import AIRAG from "./AI.vue";
import AIData from "./AIData.vue";
import D3 from "./3DModel.vue";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForms.checkPass !== "") {
          this.$refs.ruleForms.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForms.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var checkEmail = (rule, value, callback) => {
      //邮箱校验  ---只有在data()里面定义的方法能在data中的return里面的rules中的某些规则中调用，也就是这些方法需要在methods同级中定义，如果在methods中定义是无法在rules中调用的
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      const emails = value.split(";");
      if (!value) {
        return callback("邮箱地址不能为空");
      }
      setTimeout(() => {
        for (let i = 0; i < emails.length; i++) {
          console.log("邮箱地址:" + emails[i].trim());
          if (!mailReg.test(emails[i].trim())) {
            callback("邮箱地址格式不正确： " + emails[i].trim());
          }
        }
        callback(); // 所有邮件地址都合法
      }, 100);
    };
    return {
      userName: "",
      phoneNum: "",
      Email: "",
      psw: "",
      confirmPsw: "",
      // 登录btn是否显示
      loginBtn:true,
      Arr: [
        { name: "通用知识问答", id: 0, url: "/AI" },
        { name: "数据库", id: 1, url: "AIData" },
      ],
      // 是否显示3d页面
      D3show: false,
      index: 0,
      loginShow: false,
      registShow: false,
      // 记住密码
      checked: true,
      // 登录信息
      ruleForm: {
        name: "",
        password: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码字数不小于6个", trigger: "blur" },
        ],
      },
      // 注册信息
      ruleForms: {
        pass: "",
        checkPass: "",
        account: "",
        email: "",
        phone: "",
      },
      rule: {
        pass: [{ required: true, validator: validatePass, trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        account: [
          { required: true, message: "请输入账户名", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        email: [{ required: true, validator: checkEmail, trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    AIRAG: AIRAG,
    AIData: AIData,
    D3: D3,
  },
  mounted(){
    // if()
  },
  methods: {
    // 返回地图页
    backMap(){
      this.$router.push('/map')
    },
    logout() {
      this.$get("/user/logout")
        .then((res) => {
          if (res.code == 1) {
            this.$router.push("/Login");
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    cancelLogout() {
      this.visible = false;
      this.$message({
        type: "info",
        message: "已取消登出",
      });
    },
    // 登录页显示
    loginShowClick() {
      this.loginShow = true;
      this.registShow = false;
    },
    // 注册页显示
    registShowClick() {
      this.registShow = true;
      this.loginShow = false;
    },
    // 关闭登录页
    loginClose() {
      this.loginShow = false;
      this.registShow = false;
    },
    // 关闭注册页
    registClose() {
      this.registShow = false;
      this.loginShow = false;
    },
    changeIndex(i, path) {
      this.index = i;
      console.log(this.index);
      //   this.$router.push(path);
    },
    // 登录提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        console.log(this.ruleForm)
        this.loginClose()
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //  注册提交
    submitForms(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForms(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped lang="scss">
.registBox {
  position: absolute;
  width: 700px;
  height: 600px;
  background: #fff;
  left: calc(50% - 350px);
  top: 200px;
  border-radius: 10px;
  .registTitle {
    width: 100%;
    text-align: center;
    font-size: 22px;
    margin: 20px auto;
  }
  .flex {
    display: flex;
    align-items: center;
    margin: 10px;
    .input {
      width: 500px;
    }
  }
  .btnBox {
    width: 100%;
    text-align: center;
  }
  .btn {
    margin: 20px auto;
  }
}
.top {
  display: flex;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  .logo {
    img {
      width: 50px;
    }
  }
  .avatar {
    display: flex;
    .avatarLogin {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}
.aiMain {
  position: relative;
  z-index: 9;
  background: #e9e9f4;
  box-sizing: border-box;
}
.left {
  border-right: 1px solid #ccc;
  width: 60px;
  height: 100vh;
  background: #e9e9f4;
  position: absolute;
  .threeD {
    width: 30px;
    margin: 12px;
  }
}
// 登录
.login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  .loginImg {
    width: 40px;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .fromBox {
    border-bottom: 1px solid #ccc;
  }
  .login-box {
    width: 500px;
    height: 400px;
    background: #fff;
    position: absolute;
    top: 250px;
    left: calc(50% - 250px);
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    .loginIn {
      font-size: 30px;
      margin: 20px auto;
      font-weight: 600;
      cursor: pointer;
    }
    .demo-ruleForm {
      margin-left: -50px !important;
      .elBtn {
        width: 400px;
        margin-left: -20px;
      }
    }
    .flex {
      display: flex;
      justify-content: space-between;
      margin: 20px auto;
      .checkbox {
        margin-left: 80px;
      }
    }
    .forgetpsw {
      text-align: right;
      cursor: pointer;
      color: #409eff;
      font-size: 14px;
    }
    // 立即注册字样
    .registName {
      text-align: right;
      margin-top: 20px;
      cursor: pointer;
      color: #409eff;
    }
  }
}
// 注册
.regist-box {
  width: 500px;
  height: 500px;
  background: #fff;
  position: absolute;
  top: 200px;
  left: calc(50% - 250px);
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  .registImg {
    width: 40px;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .title {
    font-size: 30px;
    margin: 20px auto;
    font-weight: 600;
    cursor: pointer;
  }
  .el-form-item {
    width: 420px;
  }
  .registBtn {
    width: 320px;
    margin-left: -50px;
  }
}
.class {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  position: absolute;
  z-index: 9;
  width: 20vw;
  // top: 105px;
  top: 15px;
  left: 5px;
  .classActive {
    color: #0e1ce9;
    border-bottom: 2px solid #6d5ffd;
    line-height: 28px;
  }
  .classFont {
    color: #000;
    line-height: 28px;
    border-bottom: 2px solid transparent;
  }
}
</style>