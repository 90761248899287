<template>
    <div class="main">
        <div class="left">

        </div>
        <div class="right" id="container">

        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            
        }
    }
}
</script>
<style lang="scss" scoped>
    .mian{
        width: calc(100vw-60px);
        height: 100vh;
        background: #FFf;
        z-index: 9;
        .left{
            width: 30%;
        }
    }
</style>
